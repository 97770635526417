<template>
    <div
        class="column justify-space-between"
        style="height: calc(100vh - 90px); overflow-y: hidden"
    >
        <div class="xs1 full-height" style="padding: 28px">
            <div class="row justify-space-between align-center">
                <div
                    style="width: 336px; height: auto"
                    @click="$router.push('/')"
                >
                    <img
                        src="../assets/images/logo-sonphat.png"
                        alt=""
                        width="100%"
                    />
                </div>
                <div>
                    <div
                        class="
                            row
                            align-center
                            justify-space-between
                            primary--text
                        "
                    >
                        <div style="font-size: 28px">Trợ giúp/Hướng dẫn</div>
                        <i
                            class="mdi mdi-help-circle pl-2"
                            style="font-size: 32px"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="xs8">
            <div class="column align-center">
                <div style="font-size: 34px" class="font-bold">
                    {{
                        !error
                            ? "Thanh toán thành công. Hệ thống đang in vé..."
                            : "Hệ thống không thể in vé"
                    }}
                </div>
                <div style="font-size: 34px; text-align: center" class="pt-3">
                    {{
                        !error
                            ? "Vui lòng kiểm tra phía dưới"
                            : "Vui lòng thử lại!"
                    }}
                </div>
                <img
                    src="../assets/images/print.gif"
                    alt=""
                    style="width: 200px; height: auto"
                    class="pt-5"
                    v-if="!error"
                />
                <div class="column align-center" v-else>
                    <i
                        class="mdi mdi-alert-circle-outline danger--text"
                        style="font-size: 100px"
                    ></i>
                    <DxButton
                        id="In"
                        text="In lại"
                        type="default"
                        styling-mode="contained"
                        class="font mt-5"
                        width="163px"
                        height="58px"
                        @click="layThongTinVe"
                    />
                    <DxButton
                        id="TrangChu"
                        text="Trang chủ"
                        type="default"
                        styling-mode="text"
                        class="font mt-3"
                        width="163px"
                        height="58px"
                        @click="$router.push('/')"
                    />
                </div>
            </div>
        </div>
        <div style="position: fixed; bottom: 0; height: 20px; width: 100%">
            <div class="loading pt-2">
                <div class="progress">
                    <div class="color"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import DonHang from "~/class/DonHang";
import { DxButton } from "devextreme-vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
    IsFreePage: true,
    components: { DxButton },
    data() {
        return {
            error: false,
            maGiaoDich: "",
            DanhSachVe: [{}],
        };
    },
    computed: {
        ...mapGetters("trangChu", ["getCauHinhChung"]),
    },
    methods: {
        ...mapMutations("soDoCho", ["removeDanhSachChoDaChonLocal"]),
        ...mapActions("idleTime", ["stopIdleTimer", "startIdleTimer"]),
        checkRole(roleName) {
            if (this.$Core.Auth.TokenParsed.resource_access.BanVeTaiBen) {
                let arrRole =
                    this.$Core.Auth.TokenParsed.resource_access.BanVeTaiBen
                        .roles;
                return arrRole.some((e) => e == roleName);
            } else {
                return false;
            }
        },
        async layThongTinVe() {
            try {
                this.$startLoading;
                let banTheHienVe = await $Core.Api.Make(
                    this.$i18n.t("url.InVe"),
                    {
                        MaGiaoDich: this.maGiaoDich,
                        layFilePdf: true,
                    }
                )
                    .Config((c) => {
                        c.responseType = "blob";
                        c.validateStatus = () => true;
                    })
                    .Get();
                if (banTheHienVe.StatusCode == 200) {
                    var banTheHienVe_data =
                        await banTheHienVe.Data.arrayBuffer();
                    var printData = Array.from(
                        new Uint8Array(banTheHienVe_data)
                    )
                        .map((x) => x.toString(16))
                        .map((x) => (x.length == 1 ? "0" + x : x))
                        .join("");

                    if (
                        window.hasOwnProperty("SonPhat") &&
                        SonPhat.canPrintOverRawBT()
                    ) {
                        SonPhat.printPdfRawBT(printData);
                    } else if (
                        window.hasOwnProperty("SonPhat") &&
                        SonPhat.canPrintOverSunmi()
                    ) {
                        SonPhat.printPdfSunmi(printData);
                    } else if (window.hasOwnProperty("SonPhat")) {
                        SonPhat.printPdf(printData);
                    } else {
                    }

                    this.$stopLoading;
                    this.$router.push("/");
                    // if (ttVe.errorCode != 200) {
                    //     this.error = true;
                    //     return;
                    // }
                    // var banTheHienVes = ttVe.Data.data;
                    // this.layVeInChoMayBanVe(banTheHienVes);
                }else{
                    throw new Error("Không thể lấy bản thể hiện vé")
                }
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },
        layVeInChoMayBanVe(data) {
            let self = this;
            var printPromises = [];
            for (var datumIndex in data) {
                var datum = data[datumIndex];
                var printPromise = fetch("data:application/pdf;base64," + datum)
                    .then(function (resp) {
                        return resp.arrayBuffer();
                    })
                    .then(function (bytes) {
                        return self.print(
                            Array.from(new Int8Array(bytes)),
                            datumIndex
                        );
                    });
                printPromises.push(printPromise);
            }
            Promise.all(printPromises).then(
                function () {
                    self.$router.push("/");
                },
                function (err) {
                    console.error("Sunmi print error", err);
                    alert(err + "");
                }
            );
        },
        async print(bytes, veIndex) {
            if (typeof PrinterSunmi !== "undefined" && PrinterSunmi !== null) {
                if (window.PrinterSunmi) {
                    window.PrinterSunmi.print(bytes);

                    return true;
                }
            } else {
                throw new Error("loi ve thu " + (veIndex + 1));
            }
        },
    },
    created() {
        this.startIdleTimer(this.getCauHinhChung.thoiGianQuayLaiTrangChu);
        this.removeDanhSachChoDaChonLocal();
        let maGiaoDich = this.$route.query.maGiaoDich;
        if (maGiaoDich) {
            this.maGiaoDich = maGiaoDich;
        }
        this.layThongTinVe();
    },
};
</script>
<style lang="scss" scoped>
::v-deep.font span {
    font-size: 24px;
}
.loading {
    background-color: #ffffff;
}
.progress {
    position: relative;
    height: 10px;
    border-radius: 15px;
}
.progress .color {
    position: absolute;
    background-color: $primary;
    width: 0px;
    height: 10px;
    border-radius: 15px;
    animation: progres 4s infinite linear;
}
@keyframes progres {
    0% {
        width: 0%;
    }
    25% {
        width: 50%;
    }
    50% {
        width: 75%;
    }
    75% {
        width: 85%;
    }
    100% {
        width: 100%;
    }
} ;
</style>